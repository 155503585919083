import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import SocialMedia from "../global/SocialMedia";
import Form from "./Form";
import { FiNavigation, FiPhoneCall } from 'react-icons/fi';
import { AiFillClockCircle } from 'react-icons/ai'
import { FaCalendarAlt } from 'react-icons/fa'


function ContactInfo() {
  const { rpdata } = useContext(GlobalDataContext);

  const contentInfo = [
    {
      title: 'Location',
      subTitle: rpdata?.dbPrincipal?.location?.[0].address,
      icon: <FiNavigation fontSize={45} color={'#0bb741'} />,
      links: '',
    },
    {
      title: 'Phone',
      subTitle: rpdata?.dbPrincipal?.phones?.[0].phone,
      icon: <FiPhoneCall fontSize={45} color={'#0bb741'} />,
      links: `tel:+1${rpdata?.dbPrincipal?.phones?.[0].phone}`,
    },
    {
      title: 'Workdays',
      subTitle: rpdata?.dbPrincipal?.workdays?.[0].day,
      icon: <FaCalendarAlt fontSize={45} color={'#0bb741'} />,
      links: '',
    },
    {
      title: 'Work Hours',
      subTitle: rpdata?.dbPrincipal?.workHours?.[0].hour,
      icon: <AiFillClockCircle fontSize={45} color={'#0bb741'} />,
      links: '',
    },
  ]

  return (
    <>
      <div className="w-full md:px-14 pt-8">
        <h2 className="text-center my-6">Get In Touch!</h2>
        <div className="w-4/5 mx-auto flex">
          <div className="w-2/4 block p-6">
            {
              contentInfo.map((items, index) => {
                return (
                  <div className="px-5 py-6 shadow-lg rounded-lg my-5 mx-5 border-blue-500 border-[0.5px]" key={index}>
                    <div className="flex">
                      <div className="bg-[#009999] h-[1px] w-[20%] self-center mr-3"></div>
                      <h5>{items.title}</h5>
                    </div>
                    {
                      items.links.length > 1 ?
                        <a href={`${items.links}`}>
                          <div className="flex space-x-4">
                            {items.icon}
                            <h6>{items.subTitle}</h6>
                          </div>
                        </a>
                        :
                        <div className="flex space-x-4">
                          {items.icon}
                          <h6>{items.subTitle}</h6>
                        </div>
                    }
                  </div>
                )
              })
            }
          </div>
          <div className="w-2/4 ml-3 md:px-10 px-2 border-solid border-2 border-slate-900 rounded-[40px]">
            <h3 className="text-center">Send Us A Message</h3>
            <Form />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactInfo;
