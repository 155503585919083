import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { TbChecks } from 'react-icons/tb'

function BlockAboutOne() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-full flex justify-center md:py-32 py-10">
        <div className="max-w-7xl md:flex md:p-0 px-2 content-reverse">
          <div className="md:w-[55%]">
            <div className="w-[100%] h-[350px] md:h-[100%] flex md:flex-row justify items-center contorno rounded-lg rounded-tr-[120px] rounded-bl-[120px]">
            <div
              className="w-[100%] h-full bg-cover bg-center  rounded-lg md:-mb-16 md:-ml-12 md:rounded-tr-[150px] md:rounded-bl-[150px] rounded-tr-[120px] rounded-bl-[120px]"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[14]}")` }}
            ></div>
            
            </div>
            
          </div>
          <div className="md:w-[45%] py-4 md:px-6 px-3 self-center md:text-start text-center bg-white shadow-lg md:-ml-16 md:mt-10 md:-mb-10 rounded-lg">
            <h2 className="separator-about">A Little About Us</h2>
            <p>{rpdata?.dbAbout?.[0].text}</p>
            <ul className="list-inner-section pl-3 text-start">
              {
                rpdata?.dbAbout?.[0].list.length > 1 ?
                  rpdata?.dbAbout?.[0].list.map((item, index) => {
                    return (

                      <li key={index} className="py-2 flex items-center">
                        <TbChecks />
                        <span className="pl-2">{item}</span>
                      </li>
                  )
                })
              : null
              }
            </ul>
            <ButtonContent />
            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlockAboutOne;
